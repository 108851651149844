import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_node = _resolveComponent("node")!

  return (_openBlock(), _createBlock(_component_node, {
    title: _ctx.config.name,
    content: _ctx.content,
    "show-error": _ctx.showError,
    "error-info": _ctx.errorInfo,
    onSelected: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('selected'))),
    onDelNode: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('delNode'))),
    onInsertNode: _cache[2] || (_cache[2] = (type) => _ctx.$emit('insertNode', type)),
    placeholder: "请设置审批人",
    "header-bgc": _ctx.headerBgc,
    "header-icon": "Stamp"
  }, null, 8, ["title", "content", "show-error", "error-info", "header-bgc"]))
}